import './About.scss'


export default function About() {
    return (
      <div className="about-div"> 
      <h1 className='about-header'>ABOUT MYTHGAMESTUDIO</h1>
      <p className="about-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce erat purus, suscipit quis euismod ac, viverra quis sapien. Aenean ac arcu tincidunt, vestibulum nulla a, mattis nisi. Mauris rutrum at libero ut bibendum. Suspendisse ultricies imperdiet metus nec iaculis. Mauris et vehicula tortor. Praesent non purus sit amet velit cursus tristique quis sit amet dolor. Etiam commodo semper est a viverra. Integer vel diam bibendum, viverra diam ac, luctus magna. Suspendisse tellus nulla, feugiat non orci id, sodales molestie eros. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Integer eget quam et diam convallis tristique sed sagittis lorem. Vivamus finibus suscipit pharetra.

</p>
      </div>
    );
  }
  