
import './Games.scss'
import Slider from '../../components/Slider/Slider'


export default function Games() {
    return (
      <div className="games-container">
            <Slider/>
            </div>
    );
  }
  