import './Home.scss'

export default function Home() {
    return (
      <div className="home-container">
      <p className="home-text">Our site is under construction! <br/> <br/> Want to  <a  className="home-link" href='https://www.youtube.com/watch?v=OGUjEUhRtVU' target="_blank" rel="noreferrer" > check </a>our games meanwhile?  </p>

      </div>
    );
  }
  