import "./Blog.scss";

export default function Blog() {
  return (
    <div className="blog-container">
      <div className="blog-header"></div>
      <h1>let's talk about games</h1>
      <h1>feel free to contact</h1>
    </div>
  );
}
