import "./Contact.scss";

export default function Contact() {
  return (
    <div className="contact-container">
      <div className="contact-header"></div>
      <h1>let's talk about games</h1>
      <h1>feel free to contact</h1>
    </div>
  );
}
